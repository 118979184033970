


























import { formatRupiah } from "@/apps/core/modules/utils/text";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SimpananInfo",
  props: { simpanan: Object },
  setup() {
    return {
      // Data
      formatRupiah,
    }
  }
});
